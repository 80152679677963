<template>
  <div class="navbar-custom">
    <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
      <li>
        <button
          class="button-menu-mobile waves-effect waves-light"
          @click="toggleMenu"
        >
          <i class="fe-menu"></i>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class NavBar extends Vue {
    toggleMenu() {
      this.$parent.toggleMenu();
    }
  }
</script>

<style lang="scss">
  .navbar-custom {
    position: relative;
    display: none;
    box-shadow: none;
    z-index: 1;

    .button-menu-mobile {
      color: #6e768e;
      width: 65px;
    }

    @media screen and (max-width: 767.98px) {
      display: block;

      .button-menu-mobile {
        color: #6e768e;
      }
    }
  }

  .sidebar-enable {
    .button-menu-mobile {
      color: #6e768e;
      background-color: #fff;
    }
  }
</style>
