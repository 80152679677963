<template>
  <div id="wrapper" v-if="currentUser">
<!--    <NavBar />-->
    <SideBar
      :is-condensed="isMenuCondensed"
      v-on:toggle-menu="toggleMenu"
    />

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page">
      <div class="content">
        <!-- Start Content-->
        <div class="container-fluid">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import SideBar from '@/components/features/side-bar';
  import NavBar from '@/components/features/nav-bar';
  import { Getter } from 'vuex-class';

  @Component({
    components: {
      SideBar,
      NavBar,
    }
  })
  export default class Main extends Vue {
    isMenuCondensed = false;

    @Getter('getCurrentUser', { namespace: 'auth' })
    currentUser;

    created() {
      if (document.documentElement.clientWidth < 768) {
        document.body.classList.add('sidebar-enable');
        document.body.classList.add('enlarged');
      } else {
        document.body.classList.remove('authentication-bg');
        document.body.classList.remove('authentication-bg-pattern');
      }
    }

    toggleMenu() {
      this.isMenuCondensed = !this.isMenuCondensed;
      document.body.classList.toggle('sidebar-enable');

      // if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
      document.body.classList.toggle('enlarged');
      // }
    }
  }
</script>
