<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">

          <div class="card-body p-4">

            <div class="error-ghost text-center">
              <img src="@assets/images/owl/big-owl_5.png" alt="">
            </div>

            <div class="text-center">
              <h1 class="mt-4 text-uppercase font-weight-bold">{{ $t('common.pageNotFound') }}</h1>
              <p class="text-muted mb-0 mt-3" style="line-height: 20px;">{{ $t('common.pageNotFoundDesc') }}</p>

              <router-link to="/" class="btn btn-primary mt-3"><i class="mdi mdi-reply mr-1"></i> {{ $t('common.returnHome') }}
              </router-link>
            </div>

          </div> <!-- end card-body -->
        </div>
        <!-- end card -->

      </div> <!-- end col -->
    </div>
  </Layout>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Layout from '@/components/layouts/auth';

  @Component({
    page: {
      title: '404',
      meta: [{ name: 'description', content: '404' }],
    },
    components: { Layout },
    props: {
      resource: {
        type: String,
        default: '',
      },
    }
  })
  export default class Page404 extends Vue {}
</script>

<style lang="scss" module>
  .title {
    text-align: center;
  }

  h1 {
    color: rgb(52, 58, 64);
  }
</style>
